import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
});


function AnalyticsData() {
	const classes = useStyles();

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={4}>
					<Paper className="p-20">
						<p className='text-base text-gray-600 uppercase'>Sales</p>
						<p className='text-2xl text-black-700 my-5 font-bold'>$ 35</p>
						<p className='text-sm text-gray-600 font-semibold'><span className='text-red-600'>-3.18</span> vs. previus 7 days</p>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Paper className="p-20">
						<p className='text-base text-gray-600 uppercase'>Sales Qty</p>
						<p className='text-2xl text-black-700 my-5 font-bold'>3</p>
						<p className='text-sm text-gray-600 font-semibold'><span className='text-red-600'>-3.18</span> vs. previus 7 days</p>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Paper className="p-20">
						<p className='text-base text-gray-600 uppercase'>conversion rate</p>
						<p className='text-2xl text-black-700 my-5 font-bold'>54%</p>
						<p className='text-sm text-gray-600 font-semibold'><span className='text-red-600'>-3.18</span> vs. previus 7 days</p>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}

export default React.memo(AnalyticsData);
