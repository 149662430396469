import { authRoles } from 'app/auth';
import i18next from 'i18next';
import Revenue from './Revenue';

const RevenueConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/revenue',
			component: Revenue
		}
	]
};

export default RevenueConfig;
