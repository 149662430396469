import React, { useCallback, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { useDispatch, useSelector } from 'react-redux';
import PackageUploadTab from './PackageUpload';
import PlatformTab from './Platform';
import DescriptionTab from './Description';
import DetailsTab from './Details';
import MediaTab from './Media';
import { setVisitedTabs } from '../createPackageSlice';

const CreatePackageMain = () => {
	const dispatch = useDispatch();
	const [currentTab, setCurrentTab] = useState(0);
	const { tabHasErrors, visitedTabs } = useSelector(state => state.createPackage);

	const handleTabChange = (event, value) => {
		setCurrentTab(value);
		if (visitedTabs?.find(each => +each === +value) === undefined) {
			dispatch(setVisitedTabs(value));
		}
	};

	const firstTabLabel =
		visitedTabs?.find(each => each === 0) === 0 ? (
			tabHasErrors?.find(each => each === 0) === 0 ? (
				<>
					Package upload
					<WarningIcon />
				</>
			) : (
				<>
					Package upload <CheckCircleIcon />
				</>
			)
		) : (
			'Package upload'
		);

	const secondTabLabel = visitedTabs?.find(each => each === 1) ? (
		tabHasErrors?.find(each => each === 1) ? (
			<>
				Platform
				<WarningIcon />
			</>
		) : (
			<>
				Platform <CheckCircleIcon />
			</>
		)
	) : (
		'Platform'
	);

	const thirdTabLabel = visitedTabs?.find(each => each === 2) ? (
		tabHasErrors?.find(each => each === 2) ? (
			<>
				Description
				<WarningIcon />
			</>
		) : (
			<>
				Description <CheckCircleIcon />
			</>
		)
	) : (
		'Description'
	);

	const fourthTabLabel = visitedTabs?.find(each => each === 3) ? (
		tabHasErrors?.find(each => each === 3) ? (
			<>
				Details
				<WarningIcon />
			</>
		) : (
			<>
				Details <CheckCircleIcon />
			</>
		)
	) : (
		'Details'
	);

	const fifthTabLabel = visitedTabs?.find(each => each === 4) ? (
		tabHasErrors?.find(each => each === 4) ? (
			<>
				Media
				<WarningIcon />
			</>
		) : (
			<>
				Media <CheckCircleIcon />
			</>
		)
	) : (
		'Media'
	);

	return (
		<>
			<Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth" className="w-full mb-10">
				<Tab label={firstTabLabel} />
				<Tab label={secondTabLabel} />
				<Tab label={thirdTabLabel} />
				<Tab label={fourthTabLabel} />
				<Tab label={fifthTabLabel} />
			</Tabs>
			{currentTab === 0 && <PackageUploadTab currentTab={currentTab} />}
			{currentTab === 1 && <PlatformTab currentTab={currentTab} />}
			{currentTab === 2 && <DescriptionTab currentTab={currentTab} />}
			{currentTab === 3 && <DetailsTab currentTab={currentTab} />}
			{currentTab === 4 && <MediaTab currentTab={currentTab} />}
		</>
	);
};

export default CreatePackageMain;
