import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FuseChipSelect from '@fuse/core/FuseChipSelect';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useDispatch, useSelector } from 'react-redux';
import { removePackageDetail, setPackageDetails, setTabHasErrors, setVRDetails } from '../createPackageSlice';

const headesetList = ['Oculus Rift', 'Quest', 'Go', 'HTC vive', 'Valve Index', 'Windows Mixed Reality'];
const headsetOptions = headesetList?.map(each => ({
	value: each,
	label: each
}));

const PlatformTab = ({ currentTab }) => {
	const dispatch = useDispatch();
	const { packageDetails, tabHasErrors } = useSelector(state => state.createPackage);
	const { specifications, platforms, vr } = packageDetails;
	const [technicalDetails, setTechnicalDetails] = useState(specifications || '');
	const [platform, setPlatforms] = useState({
		mobile: !!platforms?.find(each => each === 'mobile'),
		web: !!platforms?.find(each => each === 'web'),
		desktop: !!platforms?.find(each => each === 'desktop'),
		vr: !!platforms?.find(each => each === 'vr')
	});
	const [playArea, setPlayArea] = useState(vr?.playArea[0] || 'Stationary');
	const [comfortLevel, setComfortLevel] = useState(vr?.comfortRating[0] || 'Comfortable');
	const tempHeadset = vr?.headset?.map(each => ({
		value: each,
		label: each
	}));
	const [selectedHeadset, setSelectedHeadset] = useState(tempHeadset || []);

	useEffect(() => {
		if (tabHasErrors.find(each => +each === +currentTab) === undefined && !platforms?.length) {
			if (platforms?.find(each => each === 'vr') !== undefined && !vr?.headset?.length) {
				dispatch(setTabHasErrors({ add: true, value: currentTab }));
			}
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
	}, []);

	const handlePlatformChange = e => {
		const { name, checked } = e.target;
		setPlatforms({ ...platform, [name]: checked });

		if (checked) {
			if (platforms && platforms?.length !== 0) {
				dispatch(setPackageDetails({ platforms: [...platforms, name] }));
			} else {
				dispatch(setPackageDetails({ platforms: [name] }));
			}
			if (name === 'vr') {
				dispatch(setVRDetails({ comfortRating: [comfortLevel], playArea: [playArea] }));
				dispatch(setTabHasErrors({ add: true, value: currentTab }));
			} else if (platforms?.find(each => each === 'vr') && vr?.headset === undefined) {
				dispatch(setTabHasErrors({ add: true, value: currentTab }));
			} else {
				dispatch(setTabHasErrors({ add: false, value: currentTab }));
			}
		} else if (!checked) {
			const tempPlatforms = platforms.filter(each => each !== name);
			dispatch(setPackageDetails({ platforms: tempPlatforms }));
			if (platforms?.length === 1) {
				dispatch(setTabHasErrors({ add: true, value: currentTab }));
			} else if (name === 'vr') {
				dispatch(removePackageDetail('vr'));
				dispatch(setTabHasErrors({ add: false, value: currentTab }));
			} else if (platforms?.find(each => each === 'vr') && vr?.headset === undefined) {
				dispatch(setTabHasErrors({ add: true, value: currentTab }));
			} else {
				dispatch(setTabHasErrors({ add: false, value: currentTab }));
			}
		}

		// if (name === 'vr' && checked) {
		// 	dispatch(setPackageDetails({ platforms: [name] }));
		// 	dispatch(setVRDetails({ comfortRating: [comfortLevel], playArea: [playArea] }));
		// 	dispatch(setTabHasErrors({ add: true, value: currentTab }));
		// } else if (name === 'vr' && !checked && platforms?.length) {
		// 	dispatch(setTabHasErrors({ add: false, value: currentTab }));
		// } else if (checked) {
		// 	if (platforms?.find(each => each === 'vr' && vr?.headset?.length)) {
		// 		dispatch(setTabHasErrors({ add: false, value: currentTab }));
		// 	} else {
		// 		dispatch(setTabHasErrors({ add: false, value: currentTab }));
		// 	}
		// 	if (platforms?.length) {
		// 		dispatch(setPackageDetails({ platforms: [...platforms, name] }));
		// 	} else {
		// 		dispatch(setPackageDetails({ platforms: [name] }));
		// 	}
		// } else {
		// 	const tempPlatforms = platforms.filter(each => each !== name);
		// 	dispatch(setPackageDetails({ platforms: tempPlatforms }));
		// 	if (platforms?.length === 1) dispatch(setTabHasErrors({ add: true, value: currentTab }));
		// }
	};

	const handlePlayAreaChange = e => {
		const { name, value } = e.target;
		dispatch(setVRDetails({ playArea: [value] }));
		setPlayArea(value);
	};
	const handleComfortLevel = e => {
		const { name, value } = e.target;
		dispatch(setVRDetails({ comfortRating: [value] }));
		setComfortLevel(value);
	};

	const handleTechnicalDetailsChange = e => {
		const { value } = e.target;
		setTechnicalDetails(value);
		dispatch(setPackageDetails({ specifications: value }));
	};

	const handleHeadsetChange = value => {
		setSelectedHeadset(value);
		const headsetArray = value.map(each => {
			return each.value;
		});
		dispatch(setVRDetails({ headset: headsetArray }));

		if (!value.length && tabHasErrors.find(each => +each === +currentTab) === undefined) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value.length) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};

	return (
		<Paper className="p-20">
			<InputLabel className="font-bold text-lg text-black leading-normal">Select Platforms</InputLabel>
			<FormGroup row>
				<FormControlLabel
					control={
						<Checkbox
							checked={platform.mobile}
							onChange={handlePlatformChange}
							name="mobile"
							color="primary"
						/>
					}
					label="Mobile"
				/>
				<FormControlLabel
					control={
						<Checkbox checked={platform.web} onChange={handlePlatformChange} name="web" color="primary" />
					}
					label="Web"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={platform.desktop}
							onChange={handlePlatformChange}
							name="desktop"
							color="primary"
						/>
					}
					label="Desktop"
				/>
				<FormControlLabel
					control={
						<Checkbox checked={platform.vr} onChange={handlePlatformChange} name="vr" color="primary" />
					}
					label="VR"
				/>
			</FormGroup>

			{platform?.vr ? (
				<div className="mt-20">
					<div>
						<InputLabel className="font-bold text-lg text-black leading-normal">
							Supported Headset
						</InputLabel>
						<FuseChipSelect
							value={selectedHeadset}
							onChange={handleHeadsetChange}
							isMulti
							placeholder="Select categories"
							textFieldProps={{
								variant: 'outlined'
							}}
							options={headsetOptions}
							className="my-10"
						/>
					</div>

					<div className="mt-20">
						<InputLabel className="font-bold text-lg text-black leading-normal">Play Area</InputLabel>
						<FormGroup row>
							<RadioGroup aria-label="Play Area" value={playArea} onChange={handlePlayAreaChange}>
								<FormControlLabel value="Stationary" control={<Radio />} label="Stationary" />
								<FormControlLabel value="Room scale" control={<Radio />} label="Room scale" />
							</RadioGroup>
						</FormGroup>
					</div>

					<div className="mt-20">
						<InputLabel className="font-bold text-lg text-black leading-normal">Comfort Level</InputLabel>
						<FormGroup row>
							<RadioGroup
								aria-label="Comfort Level"
								name="Comfort Level"
								value={comfortLevel}
								onChange={handleComfortLevel}
							>
								<FormControlLabel value="Comfortable" control={<Radio />} label="Comfortable" />
								<FormControlLabel value="Moderate" control={<Radio />} label="Moderate" />
								<FormControlLabel value="Intense" control={<Radio />} label="Intense" />
							</RadioGroup>
						</FormGroup>
					</div>
				</div>
			) : null}

			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Specifications</InputLabel>
				<p className="text-base text-gray-600 mt-5">List the key features of your package</p>
				<TextareaAutosize
					aria-label="minimum height"
					placeholder="List the key features of your package"
					value={technicalDetails}
					onChange={handleTechnicalDetailsChange}
					rowsMin={3}
					className="w-full border border-solid rounded mt-5 p-5 text-sm"
				/>
			</div>
		</Paper>
	);
};

export default PlatformTab;
