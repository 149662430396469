import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import http, { headers } from 'app/utils/http';

const initState = {
	isOpen: false,
	tabHasErrors: [],
	visitedTabs: [0],
	categoryList: {
		loading: false,
		response: [],
		error: ''
	},
	packageUpload: {
		loading: false,
		response: '',
		error: ''
	},
	createPackage: {
		loading: false,
		response: '',
		error: ''
	},
	deletePackage: {
		loading: false,
		response: '',
		error: ''
	},
	ssAndVideoUpload: {
		loading: false,
		response: '',
		error: ''
	},
	marketingImgUpload: {
		loading: false,
		response: '',
		error: ''
	},
	thumbnailImgUpload: {
		loading: false,
		response: '',
		error: ''
	},
	packageDetails: {}
};
const createPackageSlice = createSlice({
	name: 'create-package',
	initialState: initState,
	reducers: {
		openDialog: (state, action) => {
			state.isOpen = true;
		},
		closeDialog: (state, action) => {
			state.isOpen = false;
		},
		categoryListBegin: state => {
			state.categoryList.loading = true;
		},
		categoryListSuccess: (state, action) => {
			state.categoryList.loading = false;
			state.categoryList.response = action.payload;
		},
		categoryListFailure: (state, action) => {
			state.categoryList.loading = false;
			state.categoryList.error = action.payload;
		},
		setPackageDetails: (state, action) => {
			state.packageDetails = { ...state.packageDetails, ...action.payload };
		},
		removePackageDetail: (state, action) => {
			const details = state.packageDetails;
			delete details[action.payload];
			state.packageDetails = details;
		},
		removeMediaDetail: (state, action) => {
			const details = state.packageDetails.mobileAndWeb;
			delete details[action.payload];
			state.packageDetails.mobileAndWeb = details;
		},
		setPackageMedia: (state, action) => {
			state.packageDetails = {
				...state.packageDetails,
				mobileAndWeb: { ...state.packageDetails.mobileAndWeb, ...action.payload }
			};
		},
		setVRDetails: (state, action) => {
			state.packageDetails = {
				...state.packageDetails,
				vr: { ...state.packageDetails.vr, ...action.payload }
			};
		},
		setTabHasErrors: (state, action) => {
			if (action.payload.add) {
				if (state.tabHasErrors.find(each => +each === +action.payload.value) === undefined) {
					state.tabHasErrors = [...state.tabHasErrors, action.payload.value];
				}
			} else {
				const errors = state.tabHasErrors.filter(each => each !== action.payload.value);
				state.tabHasErrors = errors;
			}
		},
		setVisitedTabs: (state, action) => {
			state.visitedTabs = [...state.visitedTabs, action.payload];
		},
		packageUploadBegin: state => {
			state.packageUpload.loading = true;
		},
		packageUploadSuccess: (state, action) => {
			state.packageUpload.loading = false;
			state.packageUpload.response = action.payload;
		},
		packageUploadFailure: (state, action) => {
			state.packageUpload.loading = false;
			state.packageUpload.error = action.payload;
		},
		createNewPackageBegin: state => {
			state.createPackage.loading = true;
		},
		createNewPackageSuccess: (state, action) => {
			state.createPackage.loading = false;
			state.createPackage.response = action.payload;
		},
		createNewPackageFailure: (state, action) => {
			state.createPackage.loading = false;
			state.createPackage.error = action.payload;
		},
		deletePackageBegin: state => {
			state.deletePackage.loading = true;
		},
		deletePackageSuccess: (state, action) => {
			state.deletePackage.loading = false;
			state.deletePackage.response = action.payload;
		},
		deletePackageFailure: (state, action) => {
			state.deletePackage.loading = false;
			state.deletePackage.error = action.payload;
		},
		ssAndVideoUploadBegin: state => {
			state.ssAndVideoUpload.loading = true;
		},
		ssAndVideoUploadSuccess: (state, action) => {
			state.ssAndVideoUpload.loading = false;
			state.ssAndVideoUpload.response = action.payload;
		},
		ssAndVideoUploadFailure: (state, action) => {
			state.ssAndVideoUpload.loading = false;
			state.ssAndVideoUpload.error = action.payload;
		},
		marketingImgUploadBegin: state => {
			state.marketingImgUpload.loading = true;
		},
		marketingImgUploadSuccess: (state, action) => {
			state.marketingImgUpload.loading = false;
			state.marketingImgUpload.response = action.payload;
		},
		marketingImgUploadFailure: (state, action) => {
			state.marketingImgUpload.loading = false;
			state.marketingImgUpload.error = action.payload;
		},
		thumbnailImgUploadBegin: state => {
			state.thumbnailImgUpload.loading = true;
		},
		thumbnailImgUploadSuccess: (state, action) => {
			state.thumbnailImgUpload.loading = false;
			state.thumbnailImgUpload.response = action.payload;
		},
		thumbnailImgUploadFailure: (state, action) => {
			state.thumbnailImgUpload.loading = false;
			state.thumbnailImgUpload.error = action.payload;
		},
		resetCreatePackageState: state => {
			state = initState;
		}
	}
});

export const {
	openDialog,
	closeDialog,
	setPackageDetails,
	setVRDetails,
	setTabHasErrors,
	setVisitedTabs,
	removePackageDetail,
	removeMediaDetail,
	resetCreatePackageState
} = createPackageSlice.actions;

export default createPackageSlice.reducer;

export function getCategoryList(data) {
	const { categoryListBegin, categoryListSuccess, categoryListFailure } = createPackageSlice.actions;

	return dispatch => {
		const request = http('get', '/admin/category/getList', {}, headers, true);
		dispatch(categoryListBegin());
		request
			.then(response => {
				dispatch(categoryListSuccess(response.data.data));
			})
			.catch(err => {
				dispatch(categoryListFailure(err?.data?.message));
			});
	};
}

export function uploadPackageFile(file) {
	const { packageUploadBegin, packageUploadSuccess, packageUploadFailure } = createPackageSlice.actions;
	headers['Content-Type'] = 'multipart/form-data';
	return (dispatch, getState) => {
		const request = http('post', '/upload-video', file, headers, true);
		const state = getState().createPackage;
		dispatch(packageUploadBegin());
		request
			.then(response => {
				dispatch(packageUploadSuccess(response.data.data));
				dispatch(showMessage({ message: response?.data?.message }));
				dispatch(setPackageDetails({ packageFile: response?.data?.data?.name }));
				if (state.packageDetails.releaseNotes && state.packageDetails.version) {
					dispatch(setTabHasErrors({ add: false, value: 0 }));
				}
			})
			.catch(err => {
				dispatch(packageUploadFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error', autoHideDuration: 6000 }));
			});
	};
}

export function uploadSSAndVideos(file) {
	const { ssAndVideoUploadBegin, ssAndVideoUploadSuccess, ssAndVideoUploadFailure, setPackageMedia } =
		createPackageSlice.actions;
	return (dispatch, getState) => {
		const request = http('post', '/upload-file', file, headers, true);
		const state = getState().createPackage;
		dispatch(ssAndVideoUploadBegin());
		request
			.then(response => {
				const ssName = response?.data?.data.name.map(each => each.FileName);
				dispatch(ssAndVideoUploadSuccess(response.data.data));
				dispatch(showMessage({ message: response?.data?.message }));
				dispatch(setPackageMedia({ screenShots: ssName }));
				if (state?.packageDetails?.mobileAndWeb?.thumbnailImage) {
					dispatch(setTabHasErrors({ add: false, value: 4 }));
				}
			})
			.catch(err => {
				dispatch(ssAndVideoUploadFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error', autoHideDuration: 6000 }));
			});
	};
}

export function uploadMarketingImg(file) {
	const { marketingImgUploadBegin, marketingImgUploadSuccess, marketingImgUploadFailure, setPackageMedia } =
		createPackageSlice.actions;
	return (dispatch, getState) => {
		const state = getState().createPackage;
		const request = http('post', '/upload-file', file, headers, true);
		dispatch(marketingImgUploadBegin());
		request
			.then(response => {
				dispatch(marketingImgUploadSuccess(response.data.data));
				dispatch(showMessage({ message: response?.data?.message }));
				dispatch(setPackageMedia({ marketingImage: response?.data?.data?.name }));
				if (state?.packageDetails?.mobileAndWeb?.screenShots) {
					dispatch(setTabHasErrors({ add: false, value: 4 }));
				}
			})
			.catch(err => {
				dispatch(marketingImgUploadFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error', autoHideDuration: 6000 }));
			});
	};
}

export function uploadThumbnailImg(file) {
	const { thumbnailImgUploadBegin, thumbnailImgUploadSuccess, thumbnailImgUploadFailure, setPackageMedia } =
		createPackageSlice.actions;
	return (dispatch, getState) => {
		const state = getState().createPackage;
		const request = http('post', '/upload-file', file, headers, true);
		dispatch(thumbnailImgUploadBegin());
		request
			.then(response => {
				dispatch(thumbnailImgUploadSuccess(response.data.data));
				dispatch(showMessage({ message: response?.data?.message }));
				dispatch(setPackageMedia({ thumbnailImage: response?.data?.data?.name }));
				if (state?.packageDetails?.mobileAndWeb?.screenShots) {
					dispatch(setTabHasErrors({ add: false, value: 4 }));
				}
			})
			.catch(err => {
				dispatch(thumbnailImgUploadFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error', autoHideDuration: 6000 }));
			});
	};
}

export function createNewPackage(data, successCB) {
	const { createNewPackageBegin, createNewPackageSuccess, createNewPackageFailure } = createPackageSlice.actions;
	return dispatch => {
		const request = http('post', '/publisher/save/package', data, headers, true);
		dispatch(createNewPackageBegin());
		request
			.then(response => {
				dispatch(createNewPackageSuccess(response.data.data));
				dispatch(showMessage({ message: response?.data?.message }));
				successCB();
			})
			.catch(err => {
				dispatch(createNewPackageFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error', autoHideDuration: 6000 }));
			});
	};
}

export function deletePackage(packageId) {
	const { deletePackageBegin, deletePackageSuccess, deletePackageFailure } = createPackageSlice.actions;
	return dispatch => {
		const request = http('get', `/publisher/delete/package/${packageId}`, {}, headers, true);
		dispatch(deletePackageBegin());
		request
			.then(response => {
				dispatch(deletePackageSuccess(response.data.data));
				dispatch(showMessage({ message: response?.data?.message }));
			})
			.catch(err => {
				dispatch(deletePackageFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error' }));
			});
	};
}
