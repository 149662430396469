import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import http, { headers } from 'app/utils/http';
import jwtService from 'app/services/jwtService';
import { setUserData } from 'app/auth/store/userSlice';

const initialState = {
	allPackages: {
		loading: false,
		response: '',
		error: ''
	}
};

export const allPackagesSlice = createSlice({
	name: 'allPackages',
	initialState,
	reducers: {
		getAllPackagesBegin: state => {
			state.allPackages.loading = true;
		},
		getAllPackagesSuccess: (state, action) => {
			state.allPackages.loading = false;
			state.allPackages.response = action.payload;
		},
		getAllPackagesFailure: (state, action) => {
			state.allPackages.loading = false;
			state.allPackages.error = action.payload;
		}
	}
});

export default allPackagesSlice.reducer;

export function getAllPackages() {
	const { getAllPackagesBegin, getAllPackagesSuccess, getAllPackagesFailure } = allPackagesSlice.actions;
	const { id } = JSON.parse(localStorage.getItem('userData'));
	return dispatch => {
		const request = http('get', `/publisher/package/?id=${id}`, {}, headers, true);
		dispatch(getAllPackagesBegin());
		request
			.then(response => {
				dispatch(getAllPackagesSuccess(response.data.data));
			})
			.catch(err => {
				dispatch(getAllPackagesFailure(err?.data?.message));
			});
	};
}
