import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import formatBytes from 'app/utils/formatBytes';
import { removePackageDetail, setPackageDetails, setTabHasErrors, uploadPackageFile } from '../createPackageSlice';

const useStyles = makeStyles(theme => ({
	link: {
		fontWeight: 700,
		color: '#2196f3 !important'
	}
}));

const PackageUploadTab = ({ currentTab }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { packageDetails, packageUpload, tabHasErrors } = useSelector(state => state.createPackage);
	const { version, releaseNotes, packageFile } = packageDetails;
	const [ver, setVersion] = useState(version || '1.0.0');
	const [changeLog, setChangeLog] = useState(releaseNotes || 'First release');
	const { loading } = packageUpload;

	useEffect(() => {
		dispatch(setPackageDetails({ version: ver, releaseNotes: changeLog }));
		if (!version || !releaseNotes || !packageFile) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
	}, []);

	const handleVersionChange = e => {
		const { value } = e.target;
		setVersion(value);
		dispatch(setPackageDetails({ version: value }));
		if (!value && tabHasErrors.find(each => +each === +currentTab) === undefined) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value && releaseNotes && packageFile) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};
	const handleChangeLogChange = e => {
		const { value } = e.target;
		setChangeLog(value);
		dispatch(setPackageDetails({ releaseNotes: value }));
		if (!value && tabHasErrors.find(each => +each === +currentTab) === undefined) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value && version && packageFile) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};

	const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
		accept: '.zip',
		multiple: false,
		noClick: true,
		noKeyboard: true,
		onDrop: (accepted, rejected) => {
			if (accepted.length) {
				const formdata = new FormData();
				formdata.append('packageFile', accepted[0]);
				dispatch(setPackageDetails({ size: formatBytes(accepted[0].size) }));
				dispatch(uploadPackageFile(formdata));
			}
		}
	});

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map(e => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	return (
		<Paper className="p-20">
			<div>
				<InputLabel className="font-bold text-lg text-black leading-normal">Release Version</InputLabel>
				<p className="text-base text-gray-600">Use three-part semantic versioning (Major,Minor,Patch).</p>
				<FormControl fullWidth className="my-10">
					<OutlinedInput type="text" value={ver} onChange={handleVersionChange} />
				</FormControl>
				<div className="mt-20">
					<p className="font-bold text-lg text-black leading-normal">Release Notes</p>
					<div className="my-10">
						<p className="text-base text-gray-600">
							Include any entry for every version, with the latest changes first.
						</p>
						<TextareaAutosize
							aria-label="minimum height"
							value={changeLog}
							onChange={handleChangeLogChange}
							rowsMin={3}
							className="w-full border border-solid rounded mt-5 p-5 min-h-32 text-sm"
						/>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<p className="font-bold text-lg text-black leading-normal">
					<span className="mr-20">Package Upload</span>
					{packageFile ? (
						<Button
							className={classes.link}
							onClick={() => {
								dispatch(removePackageDetail('packageFile'));
								if (tabHasErrors.find(each => +each === +currentTab) === undefined) {
									dispatch(setTabHasErrors({ add: true, value: currentTab }));
								}
							}}
						>
							<RefreshIcon style={{ color: '#2196f3' }} className="mr-10" />
							Delete Package
						</Button>
					) : null}
				</p>
				<div className="my-10">
					<p className="text-lg text-gray-600 leading-normal">Maximum upload file size is 6 GB.</p>
				</div>

				<section className="container">
					<div {...getRootProps({ className: 'dropzone' })}>
						<input {...getInputProps()} />
						<div
							htmlFor="btn-upload"
							className="w-full flex flex-col items-center justify-center p-40 border border-solid rounded mt-10"
						>
							{loading ? (
								<FuseLoading title="Uploading..." />
							) : (
								<>
									{packageFile ? (
										<>
											<Icon className="text-6xl  text-gray-400" color="action">
												folder_zip
											</Icon>
											<span className="normal-case text-base">{packageFile} uploaded.</span>
											<span className="normal-case text-base mt-10">
												Size {packageDetails?.size}
											</span>
										</>
									) : (
										<>
											<p className="font-normal text-base text-gray-600">
												Drag 'n' drop some files here, or click to select files
											</p>
											<em className="font-normal text-base text-gray-400">
												(Only zip file will be accepted)
											</em>
											<CloudUploadIcon className="text-6xl text-gray-400" />
											<Button
												className="bg-blue-500 text-white normal-case text-base shadow-none hover:bg-blue-500 hover:shadow-none py-10"
												variant="contained"
												onClick={open}
											>
												Upload your Package
											</Button>
										</>
									)}
								</>
							)}
						</div>
					</div>
					<aside>
						{fileRejectionItems.length ? (
							<>
								<h4>Rejected files</h4>
								<ul>{fileRejectionItems}</ul>
							</>
						) : null}
					</aside>
				</section>
			</div>
		</Paper>
	);
};

export default PackageUploadTab;
