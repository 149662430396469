import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import { passwordRegex, passwordErrorMsg } from 'app/utils/validators';
import Loader from 'app/components/Loader';
import { submitRegister } from '../registerSlice';

function JWTRegisterTab(props) {
	const dispatch = useDispatch();
	const register = useSelector(({ auth }) => auth.register);
	const { loading } = useSelector(state => state.register);

	const [isFormValid, setIsFormValid] = useState(false);
	const [userType, setUserType] = useState(0);
	const formRef = useRef(null);

	function handleUserChange(event, value) {
		setUserType(value);
	}

	useEffect(() => {
		if (register.error && (register.error.username || register.error.password || register.error.email)) {
			formRef.current.updateInputsWithError({
				...register.error
			});
			disableButton();
		}
	}, [register.error]);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function handleSubmit(model) {
		console.log(model);
		const otherRequesData = {
			type: 'publisher',
			publisherType: userType,
			isProvideAssistance: true,
			userRole: 6
		};
		delete model.confirmPassword;
		const requestData = { ...otherRequesData, ...model };
		dispatch(submitRegister(requestData));
	}

	return (
		<div className="w-full">
			<Tabs value={userType} onChange={handleUserChange} variant="fullWidth" className="w-full mb-32">
				<Tab icon={<PersonIcon />} className="min-w-0" label="Individual" />
				<Tab icon={<BusinessIcon />} className="min-w-0" label="Company" />
			</Tabs>
			<Formsy
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				ref={formRef}
				className="flex flex-col justify-center w-full"
			>
				{userType === 0 ? (
					<>
						<TextFieldFormsy
							className="mb-16"
							type="text"
							name="name"
							label="Name"
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											person
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="email"
							name="emailId"
							label="Email"
							validations="isEmail"
							validationErrors={{
								isEmail: 'Please enter a valid email'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											email
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="number"
							name="phoneNumber"
							label="Mobile Number"
							validations={{
								isLength: 10
							}}
							validationErrors={{
								isLength: 'Please enter 10 digit mobile number'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											phone_iphone
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="text"
							name="pastExperience"
							label="Past Experience"
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											work
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="url"
							name="portfolioLink"
							label="Portfolio Link"
							validations="isUrl"
							validationErrors={{
								isUrl: 'Please enter valid portfolio link',
								required: 'Please enter valid'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											link
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
					</>
				) : null}

				{userType === 1 ? (
					<>
						<TextFieldFormsy
							className="mb-16"
							type="text"
							name="companyname"
							label="Company Name"
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<BusinessIcon className="text-20" color="action" />
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="url"
							name="companyWebsite"
							label="Company Website"
							validations="isUrl"
							validationErrors={{
								isUrl: 'Please enter valid website'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											language
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="email"
							name="companyEmail"
							label="Company Email"
							validations="isEmail"
							validationErrors={{
								isEmail: 'Please enter a valid email'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											email
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
						<TextFieldFormsy
							className="mb-16"
							type="text"
							name="companyDescription"
							label="Company Description"
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Min character length is 4'
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											article
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
							required
						/>
					</>
				) : null}

				<TextFieldFormsy
					className="mb-16"
					type="password"
					name="password"
					label="Password"
					validations={{
						matchRegexp: passwordRegex
					}}
					validationErrors={{
						matchRegexp: passwordErrorMsg
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Icon className="text-20" color="action">
									vpn_key
								</Icon>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<TextFieldFormsy
					className="mb-16"
					type="password"
					name="confirmPassword"
					label="Confirm Password"
					validations="equalsField:password"
					validationErrors={{
						equalsField: "Confirm Password doesn't match!"
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Icon className="text-20" color="action">
									vpn_key
								</Icon>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16 normal-case"
					aria-label="REGISTER"
					disabled={!isFormValid || loading}
					value="legacy"
				>
					<Loader show={loading} />
					{!loading ? 'Register' : null}
				</Button>
			</Formsy>
		</div>
	);
}

export default JWTRegisterTab;
