import { authRoles } from 'app/auth';
import i18next from 'i18next';
import Sales from './Sales';

const SalesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/sales',
			component: Sales
		}
	]
};

export default SalesConfig;
