import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'my-packages',
		title: 'My Packages',
		translate: 'My Packages',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'create-package-component',
				title: 'Create a Package',
				translate: 'Create a Package',
				type: 'item',
				icon: 'whatshot'
				// url: '/create-package'
			},
			{
				id: 'all-packages-component',
				title: 'All Packages',
				translate: 'All Packages',
				type: 'item',
				icon: 'whatshot',
				url: '/all-packages'
			}
		]
	},
	{
		id: 'my-business',
		title: 'My Business',
		translate: 'My Business',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'analytics-component',
				title: 'Analytics',
				translate: 'Analytics',
				type: 'item',
				icon: 'whatshot',
				url: '/analytics'
			},
			{
				id: 'sales-component',
				title: 'Sales',
				translate: 'Sales',
				type: 'item',
				icon: 'whatshot',
				url: '/sales'
			},
			{
				id: 'download-component',
				title: 'Downloads',
				translate: 'Download',
				type: 'item',
				icon: 'whatshot',
				url: '/download'
			},
			{
				id: 'revenue-component',
				title: 'Revenue',
				translate: 'Revenue',
				type: 'item',
				icon: 'whatshot',
				url: '/revenue'
			},
		]
	}
];

export default navigationConfig;
