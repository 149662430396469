import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
});


function AnalyticsData() {
	const classes = useStyles();

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Paper className="p-20">
						<div className='flex justify-between align-center'>
							<p className='text-xl text-black-700 font-bold'>Gross</p>
							<p className='text-xl text-black-700 my-5 font-bold'>$ 35</p>
						</div>
						<div className='flex justify-between align-center'>
							<p className='text-sm text-gray-600 font-semibold'>Dec 2021</p>
							<p className='text-sm text-gray-600 font-semibold'><span className='text-red-600'>-3.18</span> vs. previus month</p>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className="p-20">
						<div className='flex justify-between align-center'>
							<p className='text-xl text-black-700 font-bold'>Net</p>
							<p className='text-xl text-black-700 my-5 font-bold'>$ 35 <span className='text-gray-500'>(70%)</span></p>
						</div>
						<div className='flex justify-between align-center'>
							<p className='text-sm text-gray-600 font-semibold'>Dec 2021</p>
							<p className='text-sm text-gray-600 font-semibold'><span className='text-red-600'>-3.18</span> vs. previus month</p>
						</div>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}

export default React.memo(AnalyticsData);
