import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import http, { headers } from 'app/utils/http';

const initialState = {
	loading: false,
	response: {},
	error: ''
};

export const registerSlice = createSlice({
	name: 'register',
	initialState,
	reducers: {
		registerBegin: state => {
			return {
				...state,
				loading: true
			};
		},
		registerSuccess: (state, action) => {
			return {
				...state,
				response: action.payload,
				loading: false
			};
		},
		registerFailure: (state, action) => {
			return {
				...state,
				error: action.payload,
				loading: false
			};
		}
	}
});

export default registerSlice.reducer;

export function submitRegister(data) {
	const { registerBegin, registerSuccess, registerFailure } = registerSlice.actions;

	return dispatch => {
		const request = http('POST', '/signup', data, headers);
		dispatch(registerBegin());
		request
			.then(response => {
				dispatch(registerSuccess(response.data));
				dispatch(showMessage({ message: response?.data?.message, autoHideDuration: null }));
			})
			.catch(err => {
				dispatch(registerFailure(err?.data?.message));
				dispatch(showMessage({ message: err?.data?.message, variant: 'error', autoHideDuration: null }));
			});
	};
}
