import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import http, { headers } from 'app/utils/http';
import jwtService from 'app/services/jwtService';
import { setUserData } from 'app/auth/store/userSlice';

const initialState = {
	loading: false,
	response: {},
	error: ''
};

export const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		loginBegin: state => {
			return {
				...state,
				loading: true
			};
		},
		loginSuccess: (state, action) => {
			return {
				...state,
				response: action.payload,
				loading: false
			};
		},
		loginFailure: (state, action) => {
			return {
				...state,
				error: action.payload,
				loading: false
			};
		}
	}
});

export default loginSlice.reducer;

export function submitLogin(data) {
	const { loginBegin, loginSuccess, loginFailure } = loginSlice.actions;

	return dispatch => {
		dispatch(loginBegin());
		jwtService
			.signInWithEmailAndPassword(data)
			.then(response => {
				dispatch(loginSuccess(response.data));
				localStorage.setItem('userRole', ['user']);
				localStorage.setItem(
					'userData',
					JSON.stringify({
						email: response.data.data.emailId,
						name: response.data.data.name,
						id: response.data.data._id
					})
				);
				dispatch(
					setUserData({
						redirectUrl: '/all-packages',
						role: ['user'],
						data: response.data.data
					})
				);
				dispatch(showMessage({ message: response?.data?.message }));
			})
			.catch(error => {
				dispatch(loginFailure(error));
				dispatch(showMessage({ message: error?.data?.message, variant: 'error', autoHideDuration: null }));
			});
	};

	// return dispatch => {
	// 	const request = http('POST', '/login', data, headers);
	// 	dispatch(loginBegin());
	// 	request
	// 		.then(response => {
	// 			dispatch(loginSuccess(response.data));
	// 			jwtService.setSession(response.data.data.token);
	// 			successCB(response.data);
	// 			dispatch(showMessage({ message: response?.data?.message }));
	// 		})
	// 		.catch(err => {
	// 			dispatch(loginFailure(err));
	// 			dispatch(showMessage({ message: err?.data?.message, variant: 'error' }));
	// 		});
	// };
}
