import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2)
		}
	}
}));

export default function Loader({ show, size = 25, color = 'inherit' }) {
	const classes = useStyles();

	if (!show) return null;

	return (
		<div className={classes.root}>
			<CircularProgress color={color} size={size} />
		</div>
	);
}
