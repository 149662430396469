import axios from 'axios';
import jwtService from '../services/jwtService';

export const headers = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': '*',
	'Content-Type': 'application/json'
};

axios.defaults.baseURL = process.env.REACT_APP_PUBLISHER_API_HOST;

const http = (method = 'GET', url = '', payload = {}, options = {}, token = false) => {
	const request = {
		method,
		url,
		data: payload,
		...options
	};
	if (token) {
		const accessToken = localStorage.getItem('jwt_access_token');
		options.header = {
			Authorization: `Bearer ${accessToken}`
		};
	}

	return new Promise((resolve, reject) => {
		axios(request)
			.then(res => {
				resolve(res);
			})
			.catch(error => {
				if (error.response && error.response.data) {
					if (error.response.status === 401 && !jwtService.isAuthTokenValid()) {
						const refreshToken = jwtService.getRefreshToken();
						const accessToken = jwtService.getAccessToken();
						const expiredToken = localStorage.getItem('expired_token');

						if (!expiredToken) {
							localStorage.setItem('expired_token', accessToken);
						}

						if (jwtService.isAuthRefreshTokenValid(refreshToken)) {
							localStorage.setItem('access_token', refreshToken);
							jwtService.setSession(refreshToken);
							const retry = http(method, request, payload, token);
							resolve(retry);
						}
					}
					reject(error.response);
				} else {
					console.error(error);
				}
			});
	});
};

export default http;
