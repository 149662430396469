import { authRoles } from 'app/auth';
import i18next from 'i18next';
import AllPackages from './AllPackages';

const AllPackagesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/all-packages',
			component: AllPackages
		}
	]
};

export default AllPackagesConfig;
