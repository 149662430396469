import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
	emailCol: {
		maxWidth: 150,
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	verificationCol: {
		minWidth: 120
	},
	cadidateName: {
		textOverflow: 'ellipsis',
		maxWidth: 100,
		overflow: 'hidden',
		whiteSpace: 'noWrap'
	},
	paginationMain: {
		display: 'flex',
		alignItems: 'center'
	},
	paginationSection:{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 5
	},
	SearchTextfield:{
		'& > div':{
			padding: 15
		}
	}
});

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	}
});

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function createData(name, download, user, first, last) {
	return {
		name,
		download,
		user,
		first,
		last,
        history: [
			{ date: '2020-01-05', customerId: '11091700', amount: 3 },
			{ date: '2020-01-02', customerId: 'Anonymous', amount: 1 }
		]
	};
}

const rows = [
	createData('Frozen yoghurt', 3, 3, 'Oct 06 2021', 'Oct 19 2021'),
	createData('Ice cream sandwich', 6, 6, 'Oct 19 2021', 'Oct 25 2021'),
	createData('Eclair', 3,3, 'Oct 12 2021', 'Oct 19 2021'),
	createData('Cupcake', 1, 1, 'Oct 24 2021', 'Oct 24 2021'),
	createData('Butter Milk', 4, 4, 'Oct 06 2021', 'Oct 22 2021'),
	createData('Dhosa', 2, 2, 'Oct 19 2021', 'Oct 29 2021'),
];

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Package Name' },
	{ id: 'download', numeric: false, disablePadding: false, label: 'Downloads' },
	{ id: 'user', numeric: true, disablePadding: false, label: 'Users' },
	{ id: 'first', numeric: true, disablePadding: false, label: 'First' },
	{ id: 'last', numeric: true, disablePadding: false, label: 'Last' },
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		if (property !== 'actions') {
			onRequestSort(event, property);
		}
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
							hideSortIcon={headCell.id === 'actions'}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<>
			<TableRow className={classes.root}>
				<TableCell component="th" scope="row">
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					{row.name}
				</TableCell>
				<TableCell>{row.download}</TableCell>
				<TableCell>{row.user}</TableCell>
				<TableCell>{row.first}</TableCell>
				<TableCell>{row.last}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								History
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Customer</TableCell>
										<TableCell align="right">Amount</TableCell>
										<TableCell align="right">Total price ($)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map(historyRow => (
										<TableRow key={historyRow.date}>
											<TableCell component="th" scope="row">
												{historyRow.date}
											</TableCell>
											<TableCell>{historyRow.customerId}</TableCell>
											<TableCell align="right">{historyRow.amount}</TableCell>
											<TableCell align="right">
												{Math.round(historyRow.amount * row.price * 100) / 100}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

function SearchInput({ searchValue, setSearchValue }) {
	const classes = useStyles();
	return (
		<div>
			<FormControl className={classes.margin} fullWidth>
				<TextField
					placeholder="Search packages"
					value={searchValue}
					className={classes.SearchTextfield}
					onChange={event => setSearchValue(event.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)
					}}
				/>
			</FormControl>
		</div>
	);
}

function DownloadTable() {
	const classes = useStyles();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const anchorRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	let filteredRows = rows;

	useEffect(() => {
		if (searchValue) {
			filteredRows = rows.filter(each => {
				const tempName = each.name.toUpperCase();
				return tempName.includes(searchValue.toUpperCase());
			});
		}
	}, [searchValue]);

	const handleMenuOpen = event => setAnchorEl(event.currentTarget);
	const handleMenuClose = () => setAnchorEl(null);
	const handleChangePage = (event, newPage) => setPage(newPage);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangeRowsPerPage = value => {
		setRowsPerPage(parseInt(value, 10));
		setPage(0);
	};

	const handleClose = event => {
		handleChangeRowsPerPage(event.target.value);
		if (anchorRef.current && anchorRef.current.contains(event.target)) return;
		setAnchorEl(null);
	};

	const emptyTable = (
		<>
			<EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
			<TableBody>
				<TableRow>
					<TableCell align="center" colSpan={7}>
						No Record Found !
					</TableCell>
				</TableRow>
			</TableBody>
		</>
	);

	return (
		<>
			<div>
				<Paper elevation={0}>
					<TableContainer>
						<SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
						<Table aria-labelledby="tableTitle" aria-label="enhanced table">
							{filteredRows ? (
								<>
									<EnhancedTableHead
										classes={classes}
										order={order}
										orderBy={orderBy}
										onRequestSort={handleRequestSort}
										rowCount={rows.length}
									/>
									<TableBody>
										{stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
											return <Row key={row.name} row={row} />;
										})}

										{/* // {stableSort(rows, getComparator(order, orderBy))
										// 	.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										// 	.map((row, index) => {
										// 		return <Row key={row.name} row={row} />;
										// 	})} */}
									</TableBody>
								</>
							) : (
								emptyTable
							)}
						</Table>
					</TableContainer>
				</Paper>
				{rows ? (
					<div className={classes.paginationSection}>
						<div className={classes.paginationMain}>
							{/* <Typography className={styles.showTable}>Show: </Typography> */}
							<Button
								ref={anchorRef}
								aria-haspopup="true"
								onClick={handleMenuOpen}
								// className={styles.recordBtn}
							>
								{rowsPerPage} Rows
								<KeyboardArrowDownIcon style={{ marginLeft: 10 }} />
							</Button>
							<Popper
								open={Boolean(anchorEl)}
								anchorEl={anchorRef.current}
								role={undefined}
								transition
								disablePortal
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
										}}
									>
										<Paper>
											<Menu
												anchorEl={anchorEl}
												open={Boolean(anchorEl)}
												onClose={handleMenuClose}
											>
												<MenuItem value="5" onClick={handleClose}>
													5 Rows
												</MenuItem>
												<MenuItem value="10" onClick={handleClose}>
													10 Rows
												</MenuItem>
												<MenuItem value="25" onClick={handleClose}>
													25 Rows
												</MenuItem>
											</Menu>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
						<Pagination
							count={Math.ceil(rows.length / rowsPerPage)}
							page={page}
							defaultPage={1}
							onChange={handleChangePage}
							siblingCount={0}
							boundaryCount={1}
						/>
					</div>
				) : null}
			</div>
		</>
	);
}

export default React.memo(DownloadTable);
