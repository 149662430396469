import { authRoles } from 'app/auth';
import i18next from 'i18next';
import Analytics from './Analytics';

const AnalyticsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/analytics',
			component: Analytics
		}
	]
};

export default AnalyticsConfig;
