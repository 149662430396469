import { Button, FormControl, InputLabel, Select, MenuItem, Typography, Menu } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
    headerSection: {
        width: 380
    },
    dropDown: {
        padding: '12px 12px 10px'
    },
    downloadBtn:{
        "@media screen and (max-width:767px)":{
            display: 'none'
        }
    }
})

const Header = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className='flex justify-between align-center flex-wrap'>
            <Typography variant="h5">
                Analytics
            </Typography>
            <div className="flex align-center">
                <select name="cars" id="cars" className='bg-white-500 text-blue-500 normal-case text-base border border-solid rounded mr-10'>
                    <option value="volvo">Assets</option>
                    <option value="saab">Last 7 days</option>
                    <option value="opel">Last 7 days</option>
                    <option value="audi">Last 7 days</option>
                </select>
                <select name="cars" id="cars" className='bg-white-500 text-blue-500 normal-case text-base border border-solid rounded mr-10'>
                    <option value="volvo">Last 7 days</option>
                    <option value="saab">Last 7 days</option>
                    <option value="opel">Last 7 days</option>
                    <option value="audi">Last 7 days</option>
                </select>
                <Button variant="outlined" className='text-blue-500 normal-case text-base shadow-none hover:bg-blue-500 hover:shadow-none py-5 mr-10'>
                    <GetAppIcon /><span className={classes.downloadBtn}>Download CSV</span>
                </Button>
            </div>
        </div>
    );
};


export default Header;
