import DemoContent from '@fuse/core/DemoContent';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RevenueTable from "./components/Revenue";

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));

function Revenue(props) {
	const classes = useStyles(props);
	const { t } = useTranslation('revenue');

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			header={
				<div className="p-24">
					<Typography variant="h5">
						Revenue
					</Typography>
				</div>
			}
			content={
				<div className="p-24">
					<RevenueTable />
				</div>
			}
		/>
	);
}

export default Revenue;
