import DemoContent from '@fuse/core/DemoContent';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AllPackagesTable from './components/PackagesTable';
import TableToolbar from './components/TableToolbar';

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));

function AllPackages(props) {
	const classes = useStyles(props);
	const { t } = useTranslation('all-packages');

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			header={
				<div className="p-24">
					<h4>{t('ALL PACKAGES')}</h4>
				</div>
			}
			contentToolbar={
				<div className="px-24">
					<TableToolbar />
				</div>
			}
			content={
				<div className="p-24">
					<AllPackagesTable />
				</div>
			}
		/>
	);
}

export default AllPackages;
