import React, { useEffect, useMemo, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import FuseLoading from '@fuse/core/FuseLoading';
import {
	removeMediaDetail,
	setTabHasErrors,
	uploadSSAndVideos,
	uploadMarketingImg,
	uploadThumbnailImg
} from '../createPackageSlice';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const activeStyle = {
	borderColor: '#2196f3'
};
const acceptStyle = {
	borderColor: '#00e676'
};
const rejectStyle = {
	borderColor: '#ff1744'
};

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
};
const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
};
const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};
const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};
const link = {
	fontWeight: 700,
	color: '#2196f3 !important'
};

const toBase64 = file => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

const MediaTab = ({ currentTab }) => {
	const dispatch = useDispatch();
	const { packageDetails, ssAndVideoUpload, thumbnailImgUpload, marketingImgUpload, tabHasErrors } = useSelector(
		state => state.createPackage
	);
	const ssAndVideoLoading = ssAndVideoUpload?.loading;
	const marketingImgLoading = marketingImgUpload?.loading;
	const thumbnailImgLoading = thumbnailImgUpload?.loading;

	useEffect(() => {
		if (
			!packageDetails?.mobileAndWeb?.screenShots ||
			// !packageDetails?.mobileAndWeb?.marketingImage ||
			!packageDetails?.mobileAndWeb?.thumbnailImage
		) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
	}, []);

	const ssAndVideoDropzone = useDropzone({
		noClick: true,
		noKeyboard: true,
		accept: 'image/*',
		onDrop: async (accepted, rejected) => {
			if (accepted.length) {
				const promises = [];
				for (let i = 0; i < accepted.length; i += 1) {
					promises.push(toBase64(accepted[i]));
				}
				const files = await Promise.all(promises);
				dispatch(
					uploadSSAndVideos({
						fileType: accepted[0].type,
						file: files,
						type: 'screenshots'
					})
				);
			}
		}
	});

	const ssAndVideoDropzoneStyle = useMemo(
		() => ({
			...baseStyle,
			...(ssAndVideoDropzone.isDragActive ? activeStyle : {}),
			...(ssAndVideoDropzone.isDragAccept ? acceptStyle : {}),
			...(ssAndVideoDropzone.isDragReject ? rejectStyle : {})
		}),
		[ssAndVideoDropzone.isDragActive, ssAndVideoDropzone.isDragReject, ssAndVideoDropzone.isDragAccept]
	);

	const ssAndVideoeRejectionItems = ssAndVideoDropzone?.fileRejections?.map(({ file, errors }) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map(e => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	const marketingImgDropzone = useDropzone({
		noClick: true,
		noKeyboard: true,
		accept: 'image/*',
		multiple: false,
		onDrop: async (accepted, rejected) => {
			if (accepted.length) {
				const reader = new FileReader();
				reader.readAsDataURL(accepted[0]);
				reader.onloadend = () => {
					dispatch(
						uploadMarketingImg({
							fileType: accepted[0].type,
							file: reader.result,
							type: 'marketing'
						})
					);
				};
			}
		}
	});

	const marketingImgDropzoneStyle = useMemo(
		() => ({
			...baseStyle,
			...(marketingImgDropzone.isDragActive ? activeStyle : {}),
			...(marketingImgDropzone.isDragAccept ? acceptStyle : {}),
			...(marketingImgDropzone.isDragReject ? rejectStyle : {})
		}),
		[marketingImgDropzone.isDragActive, marketingImgDropzone.isDragReject, marketingImgDropzone.isDragAccept]
	);

	const marketingImgRejectionItems = marketingImgDropzone?.fileRejections?.map(({ file, errors }) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map(e => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	const thumbnailImgDropzone = useDropzone({
		noClick: true,
		noKeyboard: true,
		accept: 'image/*',
		multiple: false,
		onDrop: async (accepted, rejected) => {
			if (accepted.length) {
				const reader = new FileReader();
				reader.readAsDataURL(accepted[0]);
				reader.onloadend = () => {
					dispatch(
						uploadThumbnailImg({
							fileType: accepted[0].type,
							file: reader.result,
							type: 'thumbnail'
						})
					);
				};
			}
		}
	});

	const thumbnailImgDropzoneStyle = useMemo(
		() => ({
			...baseStyle,
			...(thumbnailImgDropzone.isDragActive ? activeStyle : {}),
			...(thumbnailImgDropzone.isDragAccept ? acceptStyle : {}),
			...(thumbnailImgDropzone.isDragReject ? rejectStyle : {})
		}),
		[thumbnailImgDropzone.isDragActive, thumbnailImgDropzone.isDragReject, thumbnailImgDropzone.isDragAccept]
	);

	const thumbnailImgRejectionItems = thumbnailImgDropzone?.fileRejections?.map(({ file, errors }) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map(e => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	const thumbnailImgPreview = (
		<div style={thumb} key={thumbnailImgUpload?.response?.name}>
			<div style={thumbInner}>
				<img src={thumbnailImgUpload?.response?.pathUrl} style={img} alt={thumbnailImgUpload?.response?.name} />
			</div>
		</div>
	);
	const marketingImgPreview = (
		<div style={thumb} key={marketingImgUpload?.response?.name}>
			<div style={thumbInner}>
				<img src={marketingImgUpload?.response?.pathUrl} style={img} alt={marketingImgUpload?.response?.name} />
			</div>
		</div>
	);
	const ssAndVideoPreview = ssAndVideoUpload?.response?.name?.map(each => (
		<div style={thumb} key={each.FileName}>
			<div style={thumbInner}>
				<img src={each.FilePath} style={img} alt={each.FileName} />
			</div>
		</div>
	));

	return (
		<Paper className="p-20">
			<InputLabel className="font-bold text-lg text-black leading-normal">
				Thumbnail Image
				{packageDetails?.mobileAndWeb?.thumbnailImage ? (
					<Button
						style={link}
						onClick={() => {
							dispatch(removeMediaDetail('thumbnailImage'));
							dispatch(setTabHasErrors({ add: true, value: currentTab }));
						}}
					>
						<RefreshIcon style={{ color: '#2196f3' }} className="mr-10" />
						Delete
					</Button>
				) : null}
			</InputLabel>
			<p className="text-base text-gray-600 mt-5">
				Upload thumbnail image(512px*512px) to showcase your package.
			</p>
			<section className="mb-20">
				<div {...thumbnailImgDropzone?.getRootProps({ style: thumbnailImgDropzoneStyle })}>
					<input {...thumbnailImgDropzone?.getInputProps()} />
					<div
						htmlFor="btn-upload"
						className="w-full flex flex-col items-center justify-center p-40 border border-solid rounded mt-10"
					>
						{thumbnailImgLoading ? (
							<FuseLoading title="Uploading..." />
						) : (
							<>
								{packageDetails?.mobileAndWeb?.thumbnailImage ? (
									<span className="bg-blue-500 text-white normal-case text-base shadow-none p-10 rounded-md">
										{packageDetails?.mobileAndWeb?.thumbnailImage} uploaded.
									</span>
								) : (
									<>
										<Button
											className="text-blue normal-case text-base shadow-none hover:bg-transparent	 hover:shadow-none py-10"
											onClick={thumbnailImgDropzone?.open}
										>
											Browse
										</Button>
										<span> or drag a images here</span>
									</>
								)}
							</>
						)}
					</div>
				</div>
				<aside>
					{thumbnailImgRejectionItems.length ? (
						<>
							<h4>Rejected files</h4>
							<ul>{thumbnailImgRejectionItems}</ul>
						</>
					) : null}
					{packageDetails?.mobileAndWeb?.thumbnailImage ? thumbnailImgPreview : null}
				</aside>
			</section>

			<InputLabel className="font-bold text-lg text-black leading-normal">
				Marketing image
				{packageDetails?.mobileAndWeb?.marketingImage ? (
					<Button
						style={link}
						onClick={() => {
							dispatch(removeMediaDetail('marketingImage'));
							dispatch(setTabHasErrors({ add: true, value: currentTab }));
						}}
					>
						<RefreshIcon style={{ color: '#2196f3' }} className="mr-10" />
						Delete
					</Button>
				) : null}
			</InputLabel>
			<p className="text-base text-gray-600 mt-5">Upload images to promote your package in the assets store.</p>

			<section className="mb-20">
				<div {...marketingImgDropzone?.getRootProps({ style: marketingImgDropzoneStyle })}>
					<input {...marketingImgDropzone?.getInputProps()} />
					<div
						htmlFor="btn-upload"
						className="w-full flex flex-col items-center justify-center p-40 border border-solid rounded mt-10"
					>
						{marketingImgLoading ? (
							<FuseLoading title="Uploading..." />
						) : (
							<>
								{packageDetails?.mobileAndWeb?.marketingImage ? (
									<span className="bg-blue-500 text-white normal-case text-base shadow-none p-10 rounded-md">
										{packageDetails?.mobileAndWeb?.marketingImage} uploaded.
									</span>
								) : (
									<>
										<Button
											className="text-blue normal-case text-base shadow-none hover:bg-transparent	 hover:shadow-none py-10"
											onClick={marketingImgDropzone?.open}
										>
											Browse
										</Button>
										<span> or drag a images here</span>
									</>
								)}
							</>
						)}
					</div>
				</div>
				<aside>
					{marketingImgRejectionItems.length ? (
						<>
							<h4>Rejected files</h4>
							<ul>{marketingImgRejectionItems}</ul>
						</>
					) : null}
					{packageDetails?.mobileAndWeb?.marketingImage ? marketingImgPreview : null}
				</aside>
			</section>

			<InputLabel className="font-bold text-lg text-black leading-normal">
				Screenshots & videos
				{packageDetails?.mobileAndWeb?.screenShots ? (
					<Button
						style={link}
						onClick={() => {
							dispatch(removeMediaDetail('screenShots'));
							if (tabHasErrors.find(each => +each === +currentTab) === undefined) {
								dispatch(setTabHasErrors({ add: true, value: currentTab }));
							}
						}}
					>
						<RefreshIcon style={{ color: '#2196f3' }} className="mr-10" />
						Delete
					</Button>
				) : null}
			</InputLabel>
			<p className="text-base text-gray-600 mt-5">Upload images to showcase your package.</p>
			<section className="mb-20">
				<div {...ssAndVideoDropzone?.getRootProps({ style: ssAndVideoDropzoneStyle })}>
					<input {...ssAndVideoDropzone?.getInputProps()} />
					<div
						htmlFor="btn-upload"
						className="w-full flex flex-col items-center justify-center p-40 border border-solid rounded mt-10"
					>
						{ssAndVideoLoading ? (
							<FuseLoading title="Uploading..." />
						) : (
							<>
								{packageDetails?.mobileAndWeb?.screenShots ? (
									<span className="bg-blue-500 text-white normal-case text-base shadow-none p-10 rounded-md">
										{packageDetails?.mobileAndWeb?.screenShots} uploaded.
									</span>
								) : (
									<>
										<Button
											className="text-blue normal-case text-base shadow-none hover:bg-transparent	 hover:shadow-none py-10"
											onClick={ssAndVideoDropzone?.open}
										>
											Browse
										</Button>
										<span> or drag a images here</span>
									</>
								)}
							</>
						)}
					</div>
				</div>
				<aside>
					{ssAndVideoeRejectionItems.length ? (
						<>
							<h4>Rejected files</h4>
							<ul>{ssAndVideoeRejectionItems}</ul>
						</>
					) : null}
					{packageDetails?.mobileAndWeb?.screenShots ? ssAndVideoPreview : null}
				</aside>
			</section>
		</Paper>
	);
};

export default MediaTab;
