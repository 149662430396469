import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const TableToolbar = () => {
	const [selectedTab, setSelectedTab] = useState(0);

	function handleTabChange(event, value) {
		setSelectedTab(value);
	}
	return (
		<Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" className="w-full">
			<Tab className="" label="All" />
			<Tab className="" label="Draft" />
			<Tab className="" label="Published" />
			<Tab className="" label="Rejected" />
		</Tabs>
	);
};

export default TableToolbar;
