import { combineReducers } from '@reduxjs/toolkit';
import register from 'app/main/register/registerSlice';
import login from 'app/main/login/loginSlice';
import createPackage from 'app/main/create-package/createPackageSlice';
import messageReducer from 'app/store/fuse/messageSlice';
import auth from 'app/auth/store';
import fuse from './fuse';
import i18n from './i18nSlice';

const createReducer = asyncReducers =>
	combineReducers({
		messageReducer,
		register,
		login,
		createPackage,
		auth,
		fuse,
		i18n,
		...asyncReducers
	});

export default createReducer;
