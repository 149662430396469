import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { FormHelperText, InputLabel } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useDispatch, useSelector } from 'react-redux';
import { setPackageDetails, setTabHasErrors } from '../createPackageSlice';

const DescriptionTab = ({ currentTab }) => {
	const dispatch = useDispatch();
	const { packageDetails } = useSelector(state => state.createPackage);
	const { shortDescription, longDescription } = packageDetails;
	const [summary, setSummary] = useState(shortDescription || '');
	const [description, setDescription] = useState(longDescription || '');
	const [errors, setErrors] = useState({
		summary: '',
		description: ''
	});

	useEffect(() => {
		if (summary === '' || description === '') {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
	}, []);

	const handleSummaryChange = e => {
		const { value } = e.target;
		setSummary(value);
		if (value.length > 100) {
			setErrors({ ...errors, summary: 'Please write description 100 characters long.' });
		} else {
			setErrors({ ...errors, summary: '' });
		}
		dispatch(setPackageDetails({ shortDescription: value }));
		if (value && value?.length <= 100 && longDescription !== '' && !errors?.description) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		} else {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
	};
	const handleDescriptionChange = e => {
		const { value } = e.target;
		setDescription(value);
		if (value.length > 4000) {
			setErrors({ ...errors, description: 'Please write description 4000 characters long.' });
		} else {
			setErrors({ ...errors, description: '' });
		}
		dispatch(setPackageDetails({ longDescription: value }));
		if (value && value?.length <= 4000 && shortDescription !== '' && !errors?.summary) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		} else {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
	};

	return (
		<Paper className="p-20">
			<InputLabel className="font-bold text-lg text-black leading-normal">Short Description</InputLabel>
			<p className="text-base text-gray-600 mt-5">
				Summarise what your package is and how it can help somebody's project.
			</p>
			<TextareaAutosize
				aria-label="minimum height"
				placeholder="Summarize your package"
				value={summary}
				onChange={handleSummaryChange}
				rowsMin={3}
				className="w-full border border-solid rounded mt-5 p-5 text-sm"
			/>
			{errors?.summary ? (
				<FormHelperText className="text-xs text-red">{errors?.summary}</FormHelperText>
			) : (
				<FormHelperText className="text-xs">100 characters</FormHelperText>
			)}
			<div className="mt-40">
				<InputLabel className="font-bold text-lg text-black leading-normal">Long Description</InputLabel>
				<p className="text-base text-gray-600 mt-5">
					Describe your package. Is the provided content customizable, suitable for a particular genre?
				</p>
				<TextareaAutosize
					aria-label="minimum height"
					placeholder="Describe your package in detail"
					value={description}
					onChange={handleDescriptionChange}
					rowsMin={4}
					className="w-full border border-solid rounded mt-5 p-5 text-sm"
				/>
				{errors?.description ? (
					<FormHelperText className="text-xs text-red">{errors?.description}</FormHelperText>
				) : (
					<FormHelperText className="text-xs">4000 characters</FormHelperText>
				)}
			</div>
		</Paper>
	);
};

export default DescriptionTab;
