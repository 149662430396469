import { authRoles } from 'app/auth';
import i18next from 'i18next';
import CreatePackage from './CreatePackage';

const CreatePackageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/create-package',
			component: CreatePackage
		}
	]
};

export default CreatePackageConfig;
