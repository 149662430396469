import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CreatePackageMain from './components/CreatePackageMain';
import Header from './components/Header';
import { resetCreatePackageState } from './createPackageSlice';

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));

function CreatePackage(props) {
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const { t } = useTranslation('createPackage');

	useEffect(() => {
		return () => {
			dispatch(resetCreatePackageState());
		};
	});

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			header={
				<div className="p-24 w-full">
					<Header />
				</div>
			}
			contentToolbar={null}
			content={
				<div className="p-24 pt-0">
					<CreatePackageMain />
				</div>
			}
		/>
	);
}

export default CreatePackage;
