import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FuseChipSelect from '@fuse/core/FuseChipSelect';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Search from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useDispatch, useSelector } from 'react-redux';
import { setPackageDetails, setTabHasErrors } from '../createPackageSlice';

const useStyles = makeStyles(theme => ({
	button: {
		fontWeight: 700,
		color: '#2196f3 !important'
	}
}));

const tagOptions = [
	'Action',
	'Adventure',
	'Arcade',
	'Board',
	'Educational',
	'Puzzle',
	'Racing',
	'Simulation',
	'Sports',
	'Trivia',
	'Word'
].map(each => ({ value: each, label: each }));

const envTypeOptions = [
	'2d images',
	'360 images',
	'Normal videos',
	'180 videos',
	'360 video',
	'360 3d video',
	'3d model'
].map(each => ({ value: each, label: each }));

const DetailsTab = ({ currentTab }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { packageDetails, tabHasErrors, categoryList } = useSelector(state => state.createPackage);
	const { tags, price, duration, privacyPolicy, assetType, environmentType, categoryId } = packageDetails;

	const [isPaid, setIsPaid] = useState(price === 0 ? 'free-assets' : 'paid-assets');
	const [playerMode, setPlayerMode] = useState(assetType || '0');
	const [cost, setCost] = useState(price || 1);
	const [costError, setCostError] = useState('');
	const [privacyPolicyLink, setPrivacyPolicyLink] = useState(privacyPolicy || '');
	const [durationTime, setDurationTime] = useState(duration || '');
	const categoryListOptions = categoryList?.response?.map(each => ({
		value: each._id,
		label: each.name
	}));
	const initialCategory = [];
	const initialTags = [];
	const initialEnvType = [];

	for (let i = 0; i < categoryListOptions?.length; i += 1) {
		for (let j = 0; j < categoryId?.length; j += 1)
			if (categoryListOptions[i].value === categoryId[j]) {
				initialCategory.push(categoryListOptions[i]);
			}
	}
	for (let i = 0; i < tagOptions?.length; i += 1) {
		for (let j = 0; j < tags?.length; j += 1)
			if (tagOptions[i].value === tags[j]) {
				initialTags.push(tagOptions[i]);
			}
	}
	for (let i = 0; i < envTypeOptions?.length; i += 1) {
		for (let j = 0; j < environmentType?.length; j += 1)
			if (envTypeOptions[i].value === environmentType[j]) {
				initialEnvType.push(envTypeOptions[i]);
			}
	}
	const [keywords, setKeywords] = useState(initialTags);
	const [category, setCategory] = useState(initialCategory);
	const [envType, setEnvType] = useState(initialEnvType);

	useEffect(() => {
		if (tabHasErrors.find(each => +each === +currentTab) === undefined && !tags && !categoryId?.length) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		}
		dispatch(
			setPackageDetails({
				price: isPaid === 'paid-assets' ? cost : 0,
				assetType: playerMode,
				languages: 'English'
			})
		);
	}, []);

	const handlePriceChange = e => {
		const { value } = e.target;
		if (+value < 1) {
			setCostError('Package cost should not be less than 1$');
		} else {
			setCostError('');
		}
		setCost(value);
		dispatch(setPackageDetails({ price: value }));

		if (value < 1) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value >= 1 && tags && categoryId && environmentType && duration) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};

	const handleCategoryChange = value => {
		setCategory(value);
		const categories = value.map(each => {
			return each.value;
		});
		dispatch(setPackageDetails({ categoryId: categories }));

		if (!value.length) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value.length && tags && !costError && environmentType && duration) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};

	const handleTagsChange = value => {
		setKeywords(value);
		const tempTags = value.map(each => {
			return each.value;
		});
		dispatch(setPackageDetails({ tags: tempTags }));

		if (!value.length) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value.length && categoryId && !costError && environmentType && duration) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};
	const handleEnvTypeChange = value => {
		setEnvType(value);
		const tempEnvType = value.map(each => {
			return each.value;
		});
		dispatch(setPackageDetails({ environmentType: tempEnvType }));

		if (!value.length) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value.length && tags && !costError && categoryId && duration) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};
	const handleDurationChange = e => {
		const { value } = e.target;
		setDurationTime(value);
		dispatch(setPackageDetails({ duration: value }));
		if (!value.length) {
			dispatch(setTabHasErrors({ add: true, value: currentTab }));
		} else if (value.length && tags && !costError && categoryId && environmentType) {
			dispatch(setTabHasErrors({ add: false, value: currentTab }));
		}
	};

	const handlePlayerModeChange = e => {
		const { value } = e.target;
		setPlayerMode(value);
		dispatch(setPackageDetails({ assetType: value }));
	};
	const handlePrivacyPolicy = e => {
		const { value } = e.target;
		setPrivacyPolicyLink(value);
		dispatch(setPackageDetails({ privacyPolicy: value }));
	};
	return (
		<Paper className="p-20">
			<InputLabel className="font-bold text-lg text-black leading-normal">Price</InputLabel>
			<p className="text-base text-gray-600 mt-5">
				The minimum price for paid assets is $4.99. Assets that are priced $15 or more can be promoted at launch
				with a discount.
			</p>
			<RadioGroup
				row
				name="price"
				value={isPaid}
				onChange={e => {
					setIsPaid(e.target.value);
					dispatch(setPackageDetails({ price: 0 }));
				}}
			>
				<FormControlLabel value="paid-assets" control={<Radio color="secondary" />} label="Paid assets" />
				<FormControlLabel value="free-assets" control={<Radio color="secondary" />} label="Free assets" />
			</RadioGroup>

			{isPaid === 'paid-assets' ? (
				<FormControl fullWidth className="my-10">
					<TextField
						type="number"
						value={cost}
						onChange={handlePriceChange}
						helperText={costError}
						error={costError !== ''}
						variant="outlined"
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>
						}}
					/>
				</FormControl>
			) : null}

			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Language</InputLabel>
				<p className="text-base text-gray-600 mt-5">English</p>
			</div>

			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Category</InputLabel>
				<FuseChipSelect
					value={category}
					onChange={handleCategoryChange}
					isMulti
					placeholder="Select categories"
					textFieldProps={{
						variant: 'outlined'
					}}
					options={categoryListOptions}
					className="my-10"
				/>
				<p className="font-bold text-base text-gray-600">
					Note:
					<span className="font-normal pl-4">
						The selected category is licensed on a single entity or multi entity basis, depending on the end
						user requirements.
					</span>
				</p>
			</div>

			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Tags</InputLabel>
				<p className="text-base text-gray-600 mt-5">
					Choose up to 5 tags to help customers find your package on the RI store. Add suggested tags or enter
					your own.
				</p>
				<FuseChipSelect
					value={keywords}
					onChange={handleTagsChange}
					isMulti
					placeholder="Select tags"
					textFieldProps={{
						variant: 'outlined'
					}}
					options={tagOptions}
					className="my-10"
				/>
			</div>
			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Player Mode</InputLabel>
				<RadioGroup row name="playerMode" value={playerMode} onChange={handlePlayerModeChange}>
					<FormControlLabel value="0" control={<Radio color="secondary" />} label="Single Player" />
					<FormControlLabel value="1" control={<Radio color="secondary" />} label="Multi Player" />
				</RadioGroup>
			</div>
			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Privacy Policy</InputLabel>
				<OutlinedInput
					placeholder="https://www.youtube.com"
					value={privacyPolicyLink}
					onChange={handlePrivacyPolicy}
					type="url"
					fullWidth
					className="my-10"
				/>
			</div>
			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Duration</InputLabel>
				<p className="text-base text-gray-600 mt-5">
					How much time the environment takes to visit for a user.(In minutes)
				</p>
				<OutlinedInput
					placeholder="Enter duration"
					value={durationTime}
					onChange={handleDurationChange}
					type="number"
					fullWidth
					className="my-10"
				/>
			</div>
			<div className="mt-20">
				<InputLabel className="font-bold text-lg text-black leading-normal">Environment Type</InputLabel>
				<FuseChipSelect
					value={envType}
					onChange={handleEnvTypeChange}
					isMulti
					placeholder="Select environment type"
					textFieldProps={{
						variant: 'outlined'
					}}
					options={envTypeOptions}
					className="my-10"
				/>
			</div>
		</Paper>
	);
};

export default DetailsTab;
