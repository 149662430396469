import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TextFieldFormsy, FuseChipSelectFormsy } from '@fuse/core/formsy';
import Formsy from 'formsy-react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import InputLabel from '@material-ui/core/InputLabel';
import { closeDialog, getCategoryList, setPackageDetails } from '../createPackageSlice';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		overflowY: 'inherit'
	}
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const useStyles = makeStyles({
	mainBox: {
		overflowY: 'inherit'
	}
});

export default function CreateNewPackage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { isOpen, categoryList } = useSelector(state => state.createPackage);
	const categoryListOptions = categoryList?.response?.map(each => ({
		value: each._id,
		label: each.name
	}));

	const [isFormValid, setIsFormValid] = useState(false);
	const formRef = useRef(null);

	const disableButton = () => setIsFormValid(false);
	const enableButton = () => setIsFormValid(true);
	const handleClose = () => dispatch(closeDialog());

	useEffect(() => {
		dispatch(getCategoryList());
	}, []);

	const handleSubmit = useCallback(model => {
		const categories = model.category.map(each => {
			return each.value;
		});
		handleClose();
		dispatch(setPackageDetails({ title: model.title, categoryId: categories }));
		history.push('/create-package');
	}, []);

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
				fullWidth
				classes={{ paper: classes.mainBox }}
			>
				<Formsy
					onValidSubmit={handleSubmit}
					onValid={enableButton}
					onInvalid={disableButton}
					ref={formRef}
					className="flex flex-col justify-center w-full"
				>
					<DialogTitle id="customized-dialog-title" onClose={handleClose}>
						Create a new Package
					</DialogTitle>
					<DialogContent dividers>
						<InputLabel className="mb-7">Package Name</InputLabel>
						<TextFieldFormsy
							className="mb-16"
							type="text"
							name="title"
							fullWidth
							placeholder="Enter package name"
							classes={{ root: 'py-12' }}
							validations={{
								minLength: 4
							}}
							validationErrors={{
								minLength: 'Package name should be at least 4 character long'
							}}
							variant="outlined"
							required
						/>

						<InputLabel className="mb-7">Category</InputLabel>
						<FuseChipSelectFormsy
							className="w-full"
							name="category"
							placeholder="Select categories"
							textFieldProps={{
								variant: 'outlined'
							}}
							options={categoryListOptions}
							isMulti
							validations={{ minLength: 1 }}
							validationErrors={{
								minLength: 'You need to select at least one category'
							}}
							required
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className="normal-case">
							Cancel
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="normal-case"
							disabled={!isFormValid}
						>
							Create my package
						</Button>
					</DialogActions>
				</Formsy>
			</Dialog>
		</div>
	);
}
