import { authRoles } from 'app/auth';
import i18next from 'i18next';
import Download from './Download';

const DownloadConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/download',
			component: Download
		}
	]
};

export default DownloadConfig;
