import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import ExampleConfig from 'app/main/example/ExampleConfig';
import RegisterConfig from 'app/main/register/RegisterConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import CreatePackageConfig from 'app/main/create-package/CreatePackageConfig';
import AllPackagesConfig from 'app/main/all-packages/AllPackagesConfig';
import RevenueConfig from 'app/main/Revenue/RevenueConfig';
import DownloadConfig from 'app/main/Download/DownloadConfig';
import AnalyticsConfig from 'app/main/Analytics/AnalyticsConfig';
import SalesConfig from 'app/main/sales/SalesConfig';

const routeConfigs = [RegisterConfig, LoginConfig, CreatePackageConfig, AllPackagesConfig, RevenueConfig, DownloadConfig, AnalyticsConfig, SalesConfig];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/login" />
	}
];

export default routes;
