import { Button, FormControl, Input, InputAdornment, OutlinedInput } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createNewPackage, setPackageDetails } from '../createPackageSlice';

const useStyles = makeStyles({
	headerSection: {
		width: 380
	},
	searchBox: {
		'&:hover': {
			'&:before': {
				borderBottom: '1px solid rgba(255, 255, 255, 0.7)'
			}
		}
	},
	backdrop: {
		color: '#fff'
	}
});

const Header = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { packageDetails, tabHasErrors, visitedTabs } = useSelector(state => state.createPackage);
	const [modelTitle, setModelTitle] = useState(packageDetails?.title || '');

	const successCB = () => history.push('/all-packages');
	const handleSubmit = () => dispatch(createNewPackage(packageDetails, successCB));

	return (
		<div className="flex justify-between align-center flex-wrap">
			<FormControl className={classes.headerSection}>
				<Input
					classes={{ underline: classes.searchBox }}
					id="search"
					endAdornment={
						<InputAdornment position="end">
							<EditIcon />
						</InputAdornment>
					}
					value={modelTitle}
					onChange={e => {
						setModelTitle(e.target.value);
						dispatch(setPackageDetails({ title: e.target.value }));
					}}
				/>
			</FormControl>
			<div className="flex align-center">
				{/* <Button className="text-blue-500 normal-case mr-10">Preview</Button>
				<Button className="text-red-500 normal-case mr-10">Delete</Button> */}
				{/* <Button
					variant="contained"
					className="bg-blue-500 text-white normal-case text-base shadow-none hover:bg-blue-500 hover:shadow-none py-5 mr-10"
				>
					Save
				</Button> */}
				<Button
					variant="contained"
					className="bg-blue-500 text-white normal-case text-base shadow-none hover:bg-blue-500 hover:shadow-none py-5"
					onClick={handleSubmit}
					disabled={tabHasErrors?.length !== 0 || visitedTabs?.length <= 4}
				>
					Submit
				</Button>
			</div>
		</div>
	);
};

export default Header;
