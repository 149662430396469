import DemoContent from '@fuse/core/DemoContent';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DownloadTable from "./components/DownloadTable"
import Header from './components/Header';

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));

function Download(props) {
	const classes = useStyles(props);
	const { t } = useTranslation('download');

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			header={
				<div className="p-24 w-full">
					<Header />
				</div>
			}
			content={
				<div className="p-24">
					<DownloadTable />
				</div>
			}
		/>
	);
}

export default Download;
